import React, { Component } from 'react';
import {observer} from "mobx-react";
import { Dropdown, Menu, Icon } from 'antd';
import config from '../config';
import Service from '../service';
import common from '../common';
import Cookies from 'js-cookie';
const Header = observer(class HeaderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: {},
      title: this.props.title || ''
    };
  }
  componentWillReceiveProps(nextProps){
    this.setState({
      title: nextProps.title || ''
    })
  }
  logout() {
    Cookies.remove('uid', { path: '/' });
    Cookies.remove('WEBJSESSIONID', { path: '/' });
    Service.logout();
    this.props.history.push({
      pathname: '/login'
    });
  }
  render() {
    let {title} = this.state;
    let uname = Cookies.get('username') || ''
    return (
      <header className="c-header">
        {title ? (
          <span class="c-header-title">{title}</span>
        ) : (
          <a className="c-header-logo" onClick={e => {
            this.props.history.push({
              pathname: '/index'
            });
          }}/>
        )}
        <Dropdown
          className="user-info"
          overlay={() => {
            return (
              <Menu>
                {uname && (
                  <Menu.Item key="1">
                    {uname}
                  </Menu.Item>
                )}
                {uname && (
                  <Menu.Divider />
                )}
                <Menu.Item key="3" onClick={e => {
                  this.logout()
                }}>退出登录</Menu.Item>
              </Menu>
            )
          }}
        >
          <span className="user-info-name" onClick={e => e.preventDefault()}>
            个人中心
            <Icon type="down" style={{marginLeft: 5}}/>
          </span>
        </Dropdown>
      </header>
    );
  }
});
export default Header;


