import {extendObservable} from "mobx";
import Service from './service';
import config from './config';


class User {
	constructor(){
		extendObservable(this, {
			entity: true
		})
	}
}

var checkPromise = null;

export const UserObj = new User();

export default {
	// 获取用户信息以检测用户状态
	checkStatus: ()=>{	
		if (checkPromise == null) {
			checkPromise = new Promise((resolve, reject)=>{
				if (UserObj.entity) {
					checkPromise = null;
					resolve(true);
				}
				else {
					Service.checkUserLogin().then( res => {
						if (res.code === 200) {
							UserObj.entity = true
							resolve(true);
						}
						else {
							resolve(false);
							checkPromise = null;
						}
					}).catch(()=>{
						resolve(false);
						checkPromise = null;
					});
				}
			});
		}
		return checkPromise;
	},
	// 是否登录
	isAuthorized: ()=>{
		return UserObj.entity ? true : false;
	}
}