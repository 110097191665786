import React, { Component } from 'react';
import {observer} from "mobx-react";
import { Upload, Icon, message } from 'antd';
import Service from '../service';
const UploadFile = observer(class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: this.props.text || '上传',
      type: this.props.type,
      uploadLoading: false,
      accept: this.props.accept || '',
      fileUrl: this.props.url || '',
      classUpload: this.props.classUpload || ''
    };
  }
  componentWillReceiveProps(nextProps){
    this.setState({
      text: nextProps.text || '上传',
      type: nextProps.type,
      accept: nextProps.accept || '',
      fileUrl: nextProps.url || '',
      classUpload: nextProps.classUpload || ''
    })
  }
  render() {
    let { accept, text, type, fileUrl, classUpload } = this.state
    return (
      <Upload
        name="avatar"
        listType="picture-card"
        className={classUpload}
        showUploadList={false}
        customRequest= {({
            action,
            data,
            file,
            filename,
            headers,
            onError,
            onProgress,
            onSuccess,
            withCredentials
        }) => {
          this.setState({
              uploadLoading: true
          });
          Service.uploadFile({
            file: file,
            type: type
          }).then(res => {
              if (res.code === 200) {
                this.props.onOk && this.props.onOk(res.data);
              }
              else {
                  message.error(res.msg || '上传失败');
              }
              this.setState({
                uploadLoading: false
              });
          });
        }}
        accept={accept}
      >
        <div>
          <Icon type="plus" style={{fontSize: 22, color: '#21b1ff'}}/>
          <div className="ant-upload-text" style={{fontSize: 12, color: '#21b1ff'}}>
            添加图片
          </div>
        </div>
      </Upload>
    );
  }
});
export default UploadFile;