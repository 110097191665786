/*
* 所有配置项功能
*/
let env = 'dev';
// let env = 'online';
// let env = 'iqiyi';
let linkConfig = {
    dev: {
        HOST: ''
    },
    online: {
        HOST: 'https://www.supercpd.com'
    },
    iqiyi: {
        HOST: 'http://aiqiyi.supercpd.com'
    }
}
export default {
    HOST: linkConfig[env].HOST,
    login: '/api/u/login',
    // 是否登录
    checkLogin: '/api/u/checkLogin',
    logout: '/api/u/logout',
    materials: '/api/material/getMaterialInfos',
    unmaterials: '/api/material/materials',
    saveMaterial: '/api/material/saveMaterial',
    managerMaterial: '/api/material/managerMaterial',
    saveMaterialInfo: '/api/material/saveMaterialInfo',
    saveUser: '/api/u/add',
    userList: '/api/u/listAll',
    userDel: '/api/u/del',
    userUpdate: '/api/u/update',
    managerUser: '/api/material/managerUser',
    versions: '/api/material/versions',
    materialInfo: '/api/material/materialInfo',
    uploadFile: '/api/upload/save_photo',
    illegalword: '/api/material/illegalword',
    lastVersionInfo: '/api/material/lastVersionInfo',
    OLD_BASE_URL:'/login'
}