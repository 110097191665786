import React, { Component } from 'react';
import {observer} from "mobx-react";
import {extendObservable} from "mobx";
import { Typography, Icon, Tag, Row, Col, Button, Table, Spin, Pagination } from 'antd';
import Header from '../components/Header';
import BindMaterial from '../components/BindMaterial';
import BindUser from '../components/BindUser'
import AdminUser from '../components/AdminUser';
import Service from '../service';
import moment from 'moment'
import Cookies from 'js-cookie';
const { Title, Text } = Typography;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
export default observer(class Index extends Component {
	constructor(props){
		super(props);
		this.state = {
			list: [],
			initLoading: true,
			warnInfo: {},
			marketId: null,
			bindInfo: {},
			// 物料数据
			materialList: [],
			currentPage: 1,
			total: 0,
			pageSize: 10,
			role: Cookies.get('role') || '',
			bindMaterialInfo: {}
		}
		extendObservable(this, {
			bindMaterial: false,
			bindMaterialAuth: false,
			bindMaterialUser: false
		});
		// 获取信息
		this.getList()
	}
	// 获取账户信息
	getList() {
		let { currentPage, pageSize } = this.state
		Service.getMaterialsList({
			userId: 1,
			currentPage: currentPage,
			size: pageSize
		}).then(res => {
			if (res.code === 200) {
				let data = res.data
				this.setState({
					initLoading: false,
					materialList: data.list,
					total: data.total
				})
			}
		})
	}
	render() {
		let {marketList, materialList, initLoading, userList, bindInfo, marketId, currentPage, total, pageSize, role, bindMaterialInfo} = this.state
		return (
			<div className={"c-data " + (this.props.type ? 'hide-header' : '')}>
				<Header {...this.props}/>
				<div className="c-data-main">
					<div className="c-data-list clearfix">
						<Row>
							<Col span={12}>
								<Text style={{fontSize: 14}}>产品列表</Text>
							</Col>
							{role === 'admin' && (
								<Col span={12} className="text-right">
									<Button className="m-r-xs" type="primary" onClick={e => {
										this.bindMaterial = true
										this.setState({
											bindMaterialInfo: {}
										})
									}}>新建产品</Button>
									<Button className="m-r-xs" type="primary" onClick={e => {
										this.bindMaterialAuth = true
									}}>添加成员</Button>
									<Button type="primary" onClick={e => {
										this.bindMaterialUser = true
									}}>成员管理</Button>
								</Col>
								)}
						</Row>
						{initLoading ? (
							<div className="example">
								<Spin indicator={antIcon}/>
							</div>
						) : (
							<Row>
								<Table pagination={false} columns={
									[
										{
											title: '#',
											dataIndex: 'index',
											render: (text, record, index) => (
											<span>{index + (currentPage - 1) * pageSize + 1}</span>
											)
										},
										{
											title: '名称',
											dataIndex: 'name',
											key: 'name',
											width: 260,
											render: (text, record, index) => (
												<div className="publish-app pb-flex">
													<div className="publish-app-img m-r-xxs" style={{width: 42, height: 42}}>
														<img  className="brand" src={record.iconR || 'https://cdn.kuchuan.com/public/main-site/images/icon-without.png'} alt={record.title || '-'}></img>
													</div>
													<div className="app-name">
														<em>{record.title}</em>
														<span>{record.packagename}</span>
													</div>
												</div>
											)
										},
										{
											title: '最新版本',
											dataIndex: 'version',
											render: (text, record, index) => (
												<span>{ text || '-'}</span>
											)
										},
										{
											title: '最近提交时间',
											dataIndex: 'updateAt',
											render: (text, record, index) => (
												<span>{ text ? moment(text).format('YYYY-MM-DD') : '-'}</span>
											)
										},
										{
											title: '负责人',
											dataIndex: 'principal',
											width: 200,
											render: (text, record, index) => (
												<span>
													{ text ? text.map((t, key) => (
														t
													)).join(',') : '-'}
													{ text.length > 0 && (
														<Icon type="edit" className="edit-user"  onClick={e => {
															this.setState({
																bindMaterialInfo: record
															}, () => {
																this.bindMaterial = true
															})
														}}/>
													)}
												</span>
											)
										},
										{
											title: '操作',
											key: 'handle',
											align: 'center',
											render: (text, record) => (
												<span>
													<Button size="small" type="primary" className="m-r-xs" onClick={e => {
														if (this.props.type) {
															this.props.history.push('/newhistory/' + record.packagename)
														}
														else {
															window.open('/history/' + record.packagename)
														}
													}}>历史版本</Button>
													<Button size="small" type="primary" ghost onClick={e => {
														if (this.props.type) {
															this.props.history.push('/newedit/' + record.packagename)
														}
														else {
															this.props.history.push('/edit/' + record.packagename)
														}
													}}>提交信息</Button>
												</span>
											),
										},
									]
								} dataSource={materialList} style={{marginTop: 18}}/>
								<div className="page-table">
									<Pagination current={currentPage} total={total} pageSize={pageSize} onChange={e => {
										this.setState({
											currentPage: e,
											initLoading: true
										}, () => {
											this.getList()
										})
									}}/>
								</div>
							</Row>
						)}
					</div>
				</div>
				<BindMaterial
					show={this.bindMaterial}
					info={bindMaterialInfo}
					marketId={marketId}
					marketList={marketList}
					onClose={() => {
						this.bindMaterial = false;
					}}
					onOk={(data) => {
						this.bindMaterial = false;
						this.getList();
					}}
				/>
				{this.bindMaterialAuth && (
					<BindUser
							show={this.bindMaterialAuth}
							userList={userList}
							onClose={() => {
									this.bindMaterialAuth = false;
							}}
							onOk={() => {
								this.getList();
								this.bindMaterialAuth = false;
							}}
					/>
				)}
				<AdminUser show={this.bindMaterialUser} onClose={() => {
						this.bindMaterialUser = false;
						this.getList();
				}}/>
			</div>
		)
	}
});