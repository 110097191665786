import React, { Component } from 'react';
import {observer} from "mobx-react";
import {extendObservable, computed, autorun} from "mobx";
import { Modal, Button, Form, Input, message, Select} from 'antd';
import Service from '../service';
const Option = Select.Option
let BindMaterial = observer(class BindMaterial extends Component {
    constructor(props){
  	    super(props);
  	    this.state = {
            show: this.props.show,
            info: this.props.info || {},
            userList: []
        }
        extendObservable(this, {
			loading: false
		});
        this.getUserList()
    }
    componentWillReceiveProps(nextProps){
  	    this.setState({
            show: nextProps.show,
            info: nextProps.info || {}
  	    })
    }
    getUserList() {
		Service.getUserList({
            currentPage: 1,
			size: 1000000
        }).then(res => {
			if (res.code === 200) {
                let data = res.data
                let list = data.list || []
				this.setState({
					initLoading: false,
					userList: list
				})
			}
		})
    }
    handleOk = e => {
        e.preventDefault();
        if (this.loading) {
            return
        }
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.loading = true
                let { info,userList } = this.state
                let api = 'saveMaterial'
                let formUserList = values.userList.map((item)=>{
                 var userId = item;
                 if(typeof userId == 'string' ){
                   userId = userList.filter((user)=>user.account == item)[0].id
                 }
                 return userId
                })
                let data = {
                    title: values.title,
                    packageName: values.packageName,
                    userIds: (formUserList || []).join(',')
                }
                if (info && info.id) {
                    data.matId = info.id
                    api = 'updateMaterial'
                }
                Service[api](data).then(res => {
                    if (res.code === 200) {
                        this.props.onOk && this.props.onOk();
                        this.props.form.resetFields();
                        message.success('添加成功');
                    }
                    else {
                        message.error(res.msg || '添加失败');
                    }
                }).catch(e => {
                    message.error('添加失败');
                }).finally(() => {
                    this.loading = false
                })
            }
        });
    };
    
    handleCancel = e => {
        this.props.onClose && this.props.onClose();
    };
    render() {
        if(!this.state.show) return null;
        const { getFieldDecorator } = this.props.form;
        let {info, userList} = this.state;
        console.log('info', info)
        return (
            <Modal
                className="new-modal"
                title={info.id ? "修改负责人" : "新建产品"}
                visible={this.state.show}
                onCancel={this.handleCancel}
                width={500}
                footer={null}
            >
                <Form>
                    <Form.Item
                        wrapperCol={{ span: 17}}
                        labelCol={{span: 7}}
                        label="产品名称"
                        extra="仅用于此平台识别显示，不上传至应用商店"
                    >
                        {getFieldDecorator('title', {
                            initialValue: info.title || '',
                            rules: [
                                {
                                    required: true,
                                    message: '产品名称不能为空',
                                },
                            ]
                        })(<Input disabled={info.title} autoComplete="off" placeholder="请输入产品名称"/>)}
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{ span: 17}}
                        labelCol={{span: 7}}
                        label="包名"
                    >
                        {getFieldDecorator('packageName', {
                            initialValue: info.packagename || '',
                            rules: [
                                {
                                    required: true,
                                    message: '包名不能为空',
                                },
                            ]
                        })(<Input disabled={info.packagename} autoComplete="off" placeholder="请输入包名"/>)}
                    </Form.Item>
                    <Form.Item
                        label="负责人"
                        wrapperCol={{ span: 17}}
                        labelCol={{span: 7}}
                    >
                        {getFieldDecorator('userList', {
                            initialValue: info.principal || []
                        })(
                            <Select maxTagCount={3} mode="multiple" placeholder="请选择负责人">
                                {userList.map((user, index) => {
                                    return (
                                        <Option value={user.id} key={index}>{user.account}</Option>
                                    )
                                })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" loading={this.loading} onClick={this.handleOk} className="login-form-button">
                            确认
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
});
BindMaterial = Form.create()(BindMaterial);
export default BindMaterial;