import React, { Component } from 'react';
import {observer} from "mobx-react";
import {extendObservable, computed, autorun} from "mobx";
import { Modal, Button, Form, Table, Popover, Select, Icon, Input, Pagination, message} from 'antd';
import Service from '../service';
const Option = Select.Option
let AdminUser = observer(class AdminUser extends Component {
    constructor(props){
  	    super(props);
  	    this.state = {
            show: this.props.show,
            userList: [],
			currentPage: 1,
			total: 0,
            pageSize: 5,
            initLoading: true
        }
        this.getUserList()
    }
    componentWillReceiveProps(nextProps){
  	    this.setState({
            show: nextProps.show
  	    }, () => {
            if (nextProps.show) {
                this.getUserList()
            }
        })
    }
    getUserList() {
        let { currentPage, pageSize } = this.state
		Service.getUserList({
            currentPage: currentPage,
			size: pageSize
        }).then(res => {
			if (res.code === 200) {
                let data = res.data
                let list = data.list || []
				this.setState({
					initLoading: false,
					userList: list.map(item => {
                        item.visible = false;
                        item.changePassword = ''
                        // 计算选中的id
                        item.materialIds = item.material.filter(m => {
                            return m.isUse
                        }).map(m => {
                            return m.id
                        })
                        return item
                    }),
					total: data.total
				})
			}
		})
    }
    delUserInfo(id) {
        Service.delUser({
            id
        }).then(res => {
            if (res.code === 200) {
				message.success('删除成功');
                this.setState({
                    currentPage: 1,
                    total: 0,
                    initLoading: false
                }, () => {
                    this.getUserList()
                })
			}
			else {
				message.error(res.msg || '删除失败');
			}
        })
    }
    updateUserInfo(item, index) {
        let {userList} = this.state
        Service.updateUser({
            account: item.account,
            password: item.changePassword,
            id: item.id
        }).then(res => {
            if (res.code === 200) {
                userList[index].changePassword = ''
                this.setState({
                    userList
                })
				message.success('更新成功');
			}
			else {
				message.error(res.msg || '更新失败');
			}
        })
    }
    updateMateria(item) {
        Service.updateUserMaterial({
            matIds: item.materialIds.join(','),
            userId: item.id
        }).then(res => {
            if (res.code === 200) {
				message.success('更新成功');
			}
			else {
				message.error(res.msg || '更新失败');
			}
        })
    }
    handleCancel = e => {
        this.props.onClose && this.props.onClose();
    };
    render() {
        if(!this.state.show) return null;
        const { getFieldDecorator } = this.props.form;
        let {currentPage, total, pageSize, userList} = this.state;
        return (
            <Modal
                className="new-modal"
                title="管理成员"
                visible={this.state.show}
                onCancel={this.handleCancel}
                width={600}
                footer={null}
            >
                <Table pagination={false} columns={
                    [
                        {
                            title: '账号',
                            dataIndex: 'account',
                            key: 'account',
                        },
                        {
                            title: '密码',
                            dataIndex: 'password',
                            key: 'password',
                            render: (text, record, index) => {
                                let {userList} = this.state
                                return (
                                    <div key={index}>
                                        <span className="m-r-xxs">{'****'}</span>
                                        <Popover
                                            content={
                                                <Form layout="inline" onSubmit={this.handleSubmit}>
                                                    <Form.Item label="密码">
                                                        <Input placeholder="请输入密码" value={record.changePassword} onChange={e => {
                                                            userList[index].changePassword = e.target.value
                                                            this.setState({
                                                                userList
                                                            })
                                                        }}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                    <Button type="primary" onClick={e => {
                                                        this.updateUserInfo(record, index)
                                                    }}>
                                                        确认
                                                    </Button>
                                                    </Form.Item>
                                                </Form>
                                            }
                                            trigger="click"
                                            visible={record.visible}
                                            onVisibleChange={e => {
                                                userList[index].visible = !record.visible
                                                this.setState({
                                                    userList
                                                })
                                            }}
                                        >
                                            <Icon type="edit" className="cursor"/>
                                        </Popover>
                                    </div>
                                )
                            }
                        },
                        {
                            title: '可管理产品',
                            dataIndex: 'time',
                            key: 'time',
                            render: (text, record, index) => (
                                <Select style={{width: 240}} maxTagCount={1} value={record.materialIds}  mode="multiple" placeholder="请选择可管理产品" onChange={e => {
                                    userList[index].materialIds = e
                                    this.setState({
                                        userList
                                    }, () => {
                                        this.updateMateria(record)
                                    })
                                }}>
                                    {record.material.map((material, key) => {
                                        return (
                                            <Option value={material.id} key={key}>{material.title}</Option>
                                        )
                                    })}
                                </Select>
                            )
                        },
                        {
                            title: '操作',
                            key: 'action',
                            align: 'center',
                            render: (text, record) => (
                            <span>
                                <Button size="small" onClick={e => {
                                    // 删除
                                    this.delUserInfo(record.id)
                                }}>删除</Button>
                            </span>
                            ),
                        },
                    ]
                } dataSource={userList} style={{marginTop: 12}}/>
                <div className="page-table">
                    <Pagination size="small" current={currentPage} total={total} pageSize={pageSize} onChange={e => {
                        this.setState({
                            currentPage: e,
                            initLoading: true
                        }, () => {
                            this.getUserList()
                        })
                    }}/>
                </div>
            </Modal>
        );
    }
});
AdminUser = Form.create()(AdminUser);
export default AdminUser;