import React, {
    Component
} from 'react';
import 'antd/dist/antd.css';
import './App.css';
import './static/css/antd.css';
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch
} from 'react-router-dom';
import {
    withRouter
} from 'react-router';
import auth from './auth';
import config from './config';
// 推广
import Login from './pages/Login';
import Index from './pages/Index';
import History from './pages/History';
import Edit from './pages/Edit';
import Dashboard from './pages/Tools/Dashboard';
// const HeaderWithRouter = withRouter(Header);
// const LeftNavWithRouter = withRouter(LeftNav);
const ScrollToTop = withRouter(class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }
    render() {
        return this.props.children
    }
});
// 路由异步代理组件
class AuthoriztionDelegate extends Component{
    constructor(props){
        super(props);
        // 检测登录状态 针对未登录或在购买页刷新网页时 因为登录状态监测是异步的
        // 直接跳转
        auth.checkStatus().then((isLogin)=>{
            if (isLogin) {
                this.props.history.replace(this.props.location.pathname);
            }
            else {
                this.props.history.replace('/login');
                return <div></div>;
            }
        });
    }
    render(){   
        return null;
    }
}
class App extends Component {
    constructor(props){
        super(props);
    }
    render() {
        return (
            <Router>
                <ScrollToTop>
                    <Switch>
                        <Route exact path="/login" component={Login}/>
                        <Route path="/index" render={(props)=>{ 
                            if (auth.isAuthorized()) {
                                return <Dashboard {...props}/>
                            }
                            else {
                                return <AuthoriztionDelegate {...props}/>
                            }
                        }}/>
                        <Route path="/history/:package" render={(props)=>{ 
                            if (auth.isAuthorized()) {
                                return <History {...props}/>
                            }
                            else {
                                return <AuthoriztionDelegate {...props}/>
                            }
                        }}/>
                        <Route path="/edit/:package" render={(props)=>{ 
                            if (auth.isAuthorized()) {
                                return <Edit {...props}/>
                            }
                            else {
                                return <AuthoriztionDelegate {...props}/>
                            }
                        }}/>
                        <Route path="/newindex" render={(props)=>{
                            return <Index {...props} type="new"/>
                        }}/>
                        <Route path="/newhistory/:package" render={(props)=>{
                            return <History {...props} type="new"/>
                        }}/>
                        <Route path="/newedit/:package" render={(props)=>{
                            return <Edit {...props} type="new"/>
                        }}/>
                        {/* 物料iframe */}
                        <Route exact path="/tools/:type" render={(props)=>{
                           return <Dashboard {...props}/>
                        }}/>
                        <Route exact path="/tools/:type/:subType" render={(props)=>{
                           return <Dashboard {...props}/>
                        }}/>
                        <Redirect to="/login" />
                    </Switch>
                </ScrollToTop>
            </Router>
        );
    }
    componentDidMount(){
        //检查登录状态
        auth.checkStatus(); 
    }
}
export default App;
