import config from './config';
import axios from 'axios';
var qs = require('qs');
axios.defaults.withCredentials = true;
axios.interceptors.response.use(function(response) {
    // 获取apiname
    try {
        if (response.config) {
            let apiName = response.config.url;
            apiName = apiName.split('/').pop();
            response.data.apiName = apiName;
        }
    }
    catch(e) {

    }
    // 用户未登录时自动跳转登录地址
    if (response.data.status === 302) {
    	window.location.href = config.OLD_BASE_URL;
    }
    return response.data;
}, function(error) {
    return Promise.reject(error);
});
function formatParams(json) {
    return qs.stringify(json);
}
/**
 * get 请求
 * 
 * @param {any} url 
 * @param {any} params 
 * @returns 
 */
function getAxios(url, params = {}, async = false) {
    params.nocache = +new Date();
    return axios.get(url, {
        params: params,
        async: async
    });
}
/**
 * post 请求
 * 
 * @param {any} url 
 * @param {any} [params={}] 
 * @returns 
 */
function postAxios(url, params = {}, form = true) {
    params.nocache = +new Date();
    return axios.post(url, form ? formatParams(params) : params);
}

export default {
    userLogin: (param) => {
        return postAxios(`${config.HOST}${config.login}`, param);
    },
    checkUserLogin: (param)=>{
        return getAxios(`${config.HOST}${config.checkLogin}`, param);
    },
    logout: (param)=>{
        return getAxios(`${config.HOST}${config.logout}`, param);
    },
    getMaterialsList: (param)=>{
		    return getAxios(`${config.HOST}${config.materials}`, param);
    },
    getUnMaterialsList: (param)=>{
		    return getAxios(`${config.HOST}${config.unmaterials}`, param);
    },
    saveMaterial: (param) => {
        return postAxios(`${config.HOST}${config.saveMaterial}`, param);
    },
    updateMaterial: (param) => {
        return postAxios(`${config.HOST}${config.managerMaterial}`, param);
    },
    saveMaterialInfo:  (param) => {
        return postAxios(`${config.HOST}${config.saveMaterialInfo}`, param);
    },
    saveUser: (param) => {
        return postAxios(`${config.HOST}${config.saveUser}`, param);
    },
    getUserList: (param)=>{
		    return getAxios(`${config.HOST}${config.userList}`, param);
    },
    delUser: (param)=>{
		    return postAxios(`${config.HOST}${config.userDel}`, param);
    },
    updateUser: (param)=>{
		    return postAxios(`${config.HOST}${config.userUpdate}`, param);
    },
    updateUserMaterial: (param)=>{
		    return postAxios(`${config.HOST}${config.managerUser}`, param);
    },
    getVersionByPackage: (param)=>{
        return getAxios(`${config.HOST}${config.versions}`, param);
    },
    getMaterialInfo:  (param)=>{
        return getAxios(`${config.HOST}${config.materialInfo}`, param);
    },
    illegalWord: (param)=>{
        return getAxios(`${config.HOST}${config.illegalword}`, param);
    },
    lastVersionInfo: (param)=>{
        return getAxios(`${config.HOST}${config.lastVersionInfo}`, param);
    },
    uploadFile(param){
        return axios({
            method: 'post',
            url: `${config.HOST}${config.uploadFile}`,
            data: {
                photos: param.file,
                type: param.type,
                nocache: +new Date()
            },
            transformRequest: [(data)=>{
				var fd = new FormData();
                fd.append('photos', data.photos);
                fd.append('type', data.type);
            	return fd;
			}]
        });
	},
}