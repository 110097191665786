import React, { Component } from "react";
import {Route} from 'react-router-dom';
import { extendObservable, computed, autorun } from "mobx";
import { observer } from "mobx-react";
import { Menu, Breadcrumb, Icon } from 'antd';
import Header from '../../components/Header';
import Service from '../../service';
import './index.css'
const { SubMenu } = Menu;
const version = 20221210
const dList = [
  {
    idx: 'wuliao',
    pathname: '应用信息',
    url: '/newindex'
  }, 
  {
      idx: 'app',
      pathname: '分包发版',
      child: [
        {
          idx: 'myapp',
          pathname: '我的应用',
          url: '/publish/application/list'
        },
        {
          idx: 'uploadapp',
          pathname: '上传应用',
          url: '/publish/index'
        },
        {
          idx: 'account',
          pathname: '账号管理',
          url: '/publish/account/list'
        }
      ]
  }, 
  {
      idx: 'market',
      pathname: '市场用户反馈',
      // url: 'http://localhost:3001/publish/feedback'
      // url: 'https://publish.kuchuan.com/publish/feedback'
      url: '/publish/feedback'
  }
]
export default observer(class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      active: this.props.match.params.type,
      subActive: this.props.match.params.subType || '',
      iframeChild: false
    };
  }
  componentWillReceiveProps(nextProps){
    this.setState({
      active: nextProps.match.params.type,
      subActive: nextProps.match.params.subType || ''
    })
  }
  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }
  handleClick = e => {
    Service.checkUserLogin().then(res => {
        if (res.code === 200) {
           let path = e.keyPath.reverse().join('/')
               this.props.history.push('/tools/' + path)
               this.setState({
                 iframeChild: false
               })
        } else if (res.code === 401){
           this.props.history.push('/login')
        }
    })
  };
  getCurrentUrl(active, subActive) {
    let result = dList.find(item => {
      return item.idx === active
    })
    if (subActive) {
      let info = result.child.find(item => {
        return item.idx === subActive
      })
      return {
        ...info,
        parent: result
      }
    }
    return {
      ...result,
      parent: {}
    }
  }
  messageListener(event) {
    const { data, origin } = event
    if (data && data.type) {
      this.setState({
        iframeChild: true
      })
    }
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.messageListener.bind(this))
  }
  componentDidMount() {
    window.addEventListener('message', this.messageListener.bind(this), false)
  }
  render() {
    let { collapsed, active, subActive, iframeChild } = this.state
    let innerHeight = window.innerHeight - 150
    let urlInfo = this.getCurrentUrl(active, subActive)
    let parentInfo = urlInfo.parent.idx ? urlInfo.parent : null
    return (
      <div className="c-data c-data-layout">
				<Header {...this.props} title="酷传统一发布"/>
        <div className="base-layout">
          <div className="layout-menu-wrapper">
            <div className="layout-menu-collapse" onClick={this.toggleCollapsed}>
              <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} />
            </div>
            <Menu
              selectedKeys={[active, subActive]}
              defaultOpenKeys={[parentInfo ? parentInfo.idx : '']}
              mode="inline"
              theme="dark"
              inlineCollapsed={collapsed}
              onClick={this.handleClick}
            >
              {dList.map((item, index) => {
                return item.child ? (
                  <SubMenu
                    key={item.idx}
                    title={
                      <span>
                        <i className={"anticon menu-icon " + item.idx}/>
                        <span>{item.pathname}</span>
                      </span>
                    }
                  >
                    {item.child.map((citem, cindex) => {
                      return (
                        <Menu.Item key={citem.idx}>{citem.pathname}</Menu.Item>
                      )
                    })}
                  </SubMenu>
                ) : (
                  <Menu.Item key={item.idx}>
                    <i className={"anticon menu-icon " + item.idx}/>
                    <span>{item.pathname}</span>
                  </Menu.Item>
                )
              })}
            </Menu>
          </div>
          <div className="layout-content-container">
            {iframeChild ? (
              <Breadcrumb>
                <Breadcrumb.Item>
                  <a onClick={() => {
                    window.location.reload()
                  }}>{urlInfo.pathname}</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  详情
                </Breadcrumb.Item>
              </Breadcrumb>
            ) : (
              parentInfo ? (
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <a onClick={() => {
                      window.location.reload()
                    }}>
                      {urlInfo.parent.pathname}
                    </a>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {urlInfo.pathname}
                  </Breadcrumb.Item>
                </Breadcrumb>
              ) : (
                <Breadcrumb>
                  <Breadcrumb.Item>{urlInfo.pathname}</Breadcrumb.Item>
                </Breadcrumb>
              )
            )}
            <div className="layout-iframe" style={{minHeight: innerHeight}}>
              <iframe src={urlInfo.url+"?"+version}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
});
