import React, { Component } from 'react';
import {observer} from "mobx-react";
import {extendObservable, computed, autorun} from "mobx";
import { Modal, Button, Form, Input, message, Select, Row, Col} from 'antd';
import Service from '../service';
let {Option} = Select;
let interval;
let BindUser = observer(class BindUser extends Component {
    constructor(props){
  	    super(props);
  	    this.state = {
            show: this.props.show,
            productList: [],
            productId: []
        }
        extendObservable(this, {
			loading: false
        });
        this.getList()
    }
    getList() {
        Service.getUnMaterialsList().then(res => {
			if (res.code === 200) {
				this.setState({
					productList: res.data
				})
			}
		})
    }
    handleOk = e => {
        e.preventDefault();
        if (this.loading) {
            return
        }
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.loading = true
                let data = {
                    account: values.account,
                    password: values.password,
                    matIds: values.matIds.join(',')
                }
                Service.saveUser(data).then(res => {
                    if (res.code === 200) {
                        this.props.onOk && this.props.onOk();
                        this.props.form.resetFields();
                        this.getList()
                        message.success('添加成功');
                    }
                    else {
                        message.error(res.msg || '添加失败');
                    }
                }).catch(e => {
                    message.error('添加失败');
                }).finally(() => {
                    this.loading = false
                })
            }
        });
      };
    
    handleCancel = e => {
        this.props.onClose && this.props.onClose();
    };
    render() {
        if(!this.state.show) return null;
        const { getFieldDecorator } = this.props.form;
        let { productId, productList } = this.state;
        return (
            <Modal
                title="添加成员"
                visible={this.state.show}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={null}
            >
                <Form>
                    <Form.Item
                        label="设置账号"
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 17 }}
                    >
                        {getFieldDecorator('account', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: '账号名称不能为空',
                                },
                            ]
                        })(<Input placeholder="请输入账号名称"/>)}
                    </Form.Item>
                    <Form.Item
                        label="设置密码"
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 17 }}
                    >
                        {getFieldDecorator('password', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: '账号密码不能为空',
                                },
                            ]
                        })(<Input.Password placeholder="请输入账号密码"/>)}
                    </Form.Item>
                    <Form.Item
                        label="可管理产品"
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 17 }}
                    >
                        {getFieldDecorator('matIds', {
                            initialValue: [],
                            rules: [
                                {
                                    required: true,
                                    message: '产品不能为空',
                                },
                            ]
                        })(
                            <Select maxTagCount={3} mode="multiple" placeholder="请选择可管理产品">
                                {productList.map((product, index) => {
                                    return (
                                        <Option value={product.id} key={index}>{product.title}</Option>
                                    )
                                })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" loading={this.loading} onClick={this.handleOk} className="login-form-button">
                            确认
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
});
BindUser = Form.create()(BindUser);
export default BindUser;