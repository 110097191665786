import React from "react";
import { extendObservable, computed, autorun } from "mobx";
import { Radio, DatePicker } from 'antd';
import moment from 'moment'
const format = "YYYY-MM-DD HH"
function disabledDate(current) {
  return current && current < moment().add(1, 'hour').startOf('hour')
}
export default class TimePub extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || '',
      choose: !!this.props.value && this.props.value != -1
    };
  }
  
  componentWillReceiveProps(nextProps){
    this.setState({
      value: nextProps.value || '',
      choose: !!nextProps.value && nextProps.value != -1
    })
  }
  render() {
    const { value, choose } = this.state;
    let time = value ? moment(value) : moment().add(1, 'hour')
    return (
      <div className="clearfix">                
        <Radio.Group value={choose} onChange={e => {
          let val = e.target.value
          this.setState({
            choose: val
          }, () => {
            if (val) {
              this.props.onOk && this.props.onOk(time.format(format))
            }
            else {
              this.props.onOk && this.props.onOk('-1')
            }
          })
        }}>
          <Radio value={false}>审核后立即发布</Radio>
          <Radio value={true}>定时发布</Radio>
        </Radio.Group>
        {choose && (
          <DatePicker
            value={time}
            format={format}
            disabledDate={disabledDate}
            onChange={val => {
              let mix = moment(val).diff(moment().add(1, 'hour'),'hour')
              if (mix < 0) {
                this.props.onOk && this.props.onOk(time.format(format))
              }
              else {
                this.props.onOk && this.props.onOk(val.format(format))
              }
            }}
            showTime={{ 
              value: moment('00', 'HH'),
              format: 'HH'
            }}
          />
        )}
      </div>
    );
  }
}
