import React, { Component } from 'react';
import {observer} from "mobx-react";
import {extendObservable} from "mobx";
import { Typography, Select, Row, Col, Form, Input, Icon, Breadcrumb, message, Button } from 'antd';
import Header from '../components/Header';
import UploadFile from '../components/UploadFile'
import Service from '../service';
import TimePub from '../components/TimePub'
import closeCircle from '../static/image/close-circle.svg'
import moment from 'moment'
const { Title, Paragraph } = Typography;
const { Option } = Select;
const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 5 },
	},
	labelAlign: 'left',
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 19 },
	},
};
const { TextArea } = Input;
let Edit = observer(class Edit extends Component {
	constructor(props){
		super(props);
		this.state = {
			packageName:  this.props.match.params.package,
			initLoading: true,
			// 图片信息
			iconR: '',
			iconZ: '',
			screenshotF: [],
			screenshotT: [],
			loading: false,
			illegalWord: ''
		}
		this.getIllegalWord()
	}
	componentDidMount(){
		this.getLastVersionInfo()
	}
	getIllegalWord() {
		Service.illegalWord().then(res => {
			let data = []
			if (res.code === 200 && res.data) {
				data = res.data.split(',')
			}
			this.setState({
				illegalWord: data
			})
		})
	}
	getLastVersionInfo() {
		Service.lastVersionInfo({
			packageName: this.state.packageName
		}).then(res => {
			if (res.code === 200) {
				this.props.form.setFieldsValue({
					recommentEight: res.data.recommenteight,
					recommentFifteen: res.data.recommentfifteen
				})
			}
		})
	}
	checkIllegalword = (rule, value, callback) => {
		let {illegalWord} = this.state
		if (value) {
			value = value.trim();
			// 判断是否存在违禁词
			let result = [];
			illegalWord.forEach(item => {
				if (value.indexOf(item) > -1 && item && result.indexOf(item) === -1) {
					result.push(item)
				}
			})
			if (result.length) {
				callback('存在违禁词语：' + result.join(','));
			}
			else {
				callback();
			}
		} 
		else {
			callback();
		}
	}
	checktSubTile = (rule, value, callback) => {
		let reg = new RegExp("^[A-Za-z0-9\u4e00-\u9fa5]+$");
		if(value && !reg.test(value)) {
			callback('只能输入数字，中文，英文');
		}
		else {
			callback();
		}
	}
	handleSubmit = e => {
		e.preventDefault();
		if (this.loading) {
			return
		}
		this.props.form.validateFields((err, values) => {
				if (!err) {
						let { packageName, iconR, iconZ, screenshotF, screenshotT } = this.state;
						// 图片数量不能低于4个，不能超过5个
						if (screenshotF.length && screenshotF.length < 4) {
							this.loading = false
							return message.error('截图(480*800px)图片数量不能低于4张');
						}
						if (screenshotT.length && screenshotT.length < 4) {
							this.loading = false
							return message.error('截图(1080*1920px)图片数量不能低于4张');
						}
						this.loading = true
						values.packageName = packageName
						values.icon_r = iconR
						values.icon_z = iconZ
						values.screenshot_f = screenshotF.join(';')
						values.screenshot_t = screenshotT.join(';')
						values.privacyPolicy = values.privacyPolicy || ''
						values.keyWord = values.keyWord ? values.keyWord.replace(/[，][ ]|[，$]/g, ',') : ''
						values.publishTime = (values.publishTime && values.publishTime != -1) ? moment(values.publishTime).startOf('minute').format('YYYY-MM-DD HH:mm:ss') : ''
						Service.saveMaterialInfo(values).then(res => {
								if (res.code === 200) {
									message.success('添加成功');
									if (this.props.type) {
										this.props.history.push('/newindex')
									}
									else {
										this.props.history.push('/index')
									}
								}
								else {
									message.error(res.msg || '添加失败');
								}
						}).catch(e => {
								message.error('添加失败');
						}).finally(() => {
								this.loading = false
						})
				}
		});
	}
	checkKeywordE = (rule, value, callback) => {
		if (value && value.length > 8) {
			callback('一句话推荐不能超过8个字')
		} 
		else {
			callback();
		}
	}
	checkKeywordF = (rule, value, callback) => {
		if (value && value.length > 15) {
			callback('一句话推荐不能超过15个字')
		} 
		else {
			callback();
		}
	}
	checkDescF = (rule, value, callback) => {
		if (value && value.length > 500) {
			callback('不能超过500个字')
		} 
		else {
			callback();
		}
	}
	checkDescT = (rule, value, callback) => {
		if (value && value.length > 1000) {
			callback('不能超过1000个字')
		} 
		else {
			callback();
		}
	}
	checkTextSub = (rule, value, callback) => {
		if (value && value.length > 100) {
			callback('不能超过100个字')
		} 
		else {
			callback();
		}
	}
	checkTextKeyword = (rule, value, callback) => {
		if (value && value.length > 100) {
			callback('不能超过100个字')
		} 
		else {
			callback();
		}
	}
	render() {
		const { getFieldDecorator } = this.props.form;
		let { packageName, iconR, iconZ, screenshotF, screenshotT } = this.state;
		return (
			<div className={"c-data " + (this.props.type ? 'hide-header' : '')}>
				{this.props.type && (
					<Breadcrumb style={{marginTop: 20, marginLeft: 20}}>
						<Breadcrumb.Item onClick={e => {
							this.props.history.push('/newindex')
						}} className="cursor"><Icon type="home" /> 应用信息</Breadcrumb.Item>
						<Breadcrumb.Item>{packageName}</Breadcrumb.Item>
					</Breadcrumb>
				)}
				<Header {...this.props}/>
				<div className="c-data-main">
					<div className="c-data-list c-data-list-edit clearfix"
					>
						<Row>
							<Col span={24} className="text-center">
								<Title level={3}>提交信息</Title>
							</Col>
						</Row>
						<Form {...formItemLayout} className="m-t">
							<Form.Item label="包名">
								{packageName}
							</Form.Item>
							<Form.Item label="版本(versionName)">
								{getFieldDecorator('version', {
									rules: [
										{
											required: true,
											message: '版本不能为空'
										}
									],
								})(<Input placeholder="请输入版本"/>)}
							</Form.Item>
							<Form.Item label="小版本(versionCode)">
								{getFieldDecorator('smallVersion', {
									rules: [
										{
											required: true,
											message: '小版本不能为空'
										}
									],
								})(<Input placeholder="请输入小版本"/>)}
							</Form.Item>
							<Form.Item label="副标题">
								{getFieldDecorator('subTitle', {
									rules: [
										{
											validator: this.checkIllegalword
										},
										{
											validator: this.checktSubTile
										}
									]
								})(<Input placeholder="请输入副标题，只能为数字，中文，英文"/>)}
							</Form.Item>
							<Form.Item label="更新说明">
								{getFieldDecorator('updateDescription', {
									rules: [
										{
											required: true,
											message: '更新说明不能为空'
										},
										{
											validator: this.checkIllegalword
										}
									],
								})(<TextArea rows={4}  placeholder="请输入更新说明"/>)}
							</Form.Item>
							<Form.Item label="一句话推荐(8字)">
								{getFieldDecorator('recommentEight', {
									rules: [
										{
											validator: this.checkKeywordE
										},
										{
											validator: this.checkIllegalword
										}
									],
								})(<Input placeholder="请输入"  />)}
							</Form.Item>
							<Form.Item label="一句话推荐(15字)">
								{getFieldDecorator('recommentFifteen', {
									rules: [
										{
											validator: this.checkKeywordF
										},
										{
											validator: this.checkIllegalword
										}
									],
								})(<Input placeholder="请输入"  />)}
							</Form.Item>
							<Form.Item label="关键词">
								{getFieldDecorator('keyWord', {
									rules: [
										{
											validator: this.checkIllegalword
										}
									]
								})(<Input placeholder="请输入关键词，用逗号分隔"  />)}
							</Form.Item>
							<Form.Item label="隐私政策" extra="相应APP的官方隐私政策网址">
								{getFieldDecorator('privacyPolicy', {
									rules: [
										{
											type: 'url',
											message: '隐私地址不合法',
										}
									]
								})(<Input placeholder="请输入隐私政策"  />)}
							</Form.Item>
							<Form.Item label="发布方式">
								{getFieldDecorator('publishTime', {
								})(
									<TimePub onOk={data => {
										this.props.form.setFieldsValue({
											publishTime: data
										})
									}}/>
								)}
							</Form.Item>
							<Form.Item label="应用描述(500字)">
								{getFieldDecorator('appDescriptionFive', {
									rules: [
										{
											validator: this.checkDescF
										},
										{
											validator: this.checkIllegalword
										}
									],
								})(<TextArea rows={4}  placeholder="请输入应用描述，不能超过500个字"/>)}
							</Form.Item>
							<Form.Item label="应用描述(1000字)">
								{getFieldDecorator('appDescriptionTen', {
									rules: [
										{
											validator: this.checkDescT
										},
										{
											validator: this.checkIllegalword
										}
									],
								})(<TextArea rows={4}  placeholder="请输入应用描述，不能超过1000个字"/>)}
							</Form.Item>
							<Form.Item label="应用描述(不限字数)">
								{getFieldDecorator('appDescription', {
									rules: [
										{
											validator: this.checkIllegalword
										}
									],
								})(<TextArea rows={4}  placeholder="请输入应用描述"/>)}
							</Form.Item>
							<Form.Item label="应用图标(圆角)">
								{iconR ? (
									<div className="file">
										<div className="file-img">
											<img alt="" width={48} className="app_icon" src={iconR}/>
										</div>
										<img className="close-circle" src={closeCircle} width={14} onClick={e => {
											this.setState({
												iconR: ''
											})
										}}/>
									</div>
								) : (
									<UploadFile type="icon_r" accept=".png" onOk={data => {
										this.setState({
											iconR: data
										})
									}}></UploadFile>
								)}
								<Paragraph>
									512*512 png 圆角
								</Paragraph>
							</Form.Item>
							<Form.Item label="应用图标(直角)">
								{iconZ ? (
									<div className="file">
										<div className="file-img">
											<img alt="" width={48} className="app_icon" src={iconZ}/>
										</div>
										<img className="close-circle" src={closeCircle} width={14} onClick={e => {
											this.setState({
												iconZ: ''
											})
										}}/>
									</div>
								) : (
									<UploadFile type="icon_z" text="512 * 512 png" accept=".png" onOk={data => {
										this.setState({
											iconZ: data
										})
									}}></UploadFile>
								)}
								<Paragraph>
									512*512 png 直角
								</Paragraph>
							</Form.Item>
							<Form.Item label="截图(480*800px)">
								<div className="c-data-banner">
									{(screenshotF && screenshotF.length) ? screenshotF.map((screen, key) => (
										<div className="c-data-banner-item" key={key} style={{backgroundImage: "url("+screen+")"}}>
											<img className="close-circle" src={closeCircle} width={14} onClick={e => {
												screenshotF.splice(key, 1)
												this.setState({
													screenshotF
												})
											}}/>
										</div>
									)) : null}
									{screenshotF.length < 5 && (
										<UploadFile classUpload="c-data-file" type="screenshot_f" accept=".png,.jpg,.jpeg"  onOk={data => {
											screenshotF.push(data)
											this.setState({
												screenshotF: screenshotF
											})
										}}></UploadFile>
									)}
								</div>
								<Paragraph>
									文件数量：4~5；文件格式：png，jpg；文件尺寸：480*800px；文件大小：不超过1M
								</Paragraph>
							</Form.Item>
							<Form.Item label="截图(1080*1920px)">
								{(screenshotT && screenshotT.length) ? screenshotT.map((screen, key) => (
									<div className="c-data-banner-item" key={key} style={{backgroundImage: "url("+screen+")"}}>
										<img className="close-circle" src={closeCircle} width={14} onClick={e => {
											screenshotT.splice(key, 1)
											this.setState({
												screenshotT
											})
										}}/>
									</div>
								)) : null}
								{screenshotT.length < 5 && (
									<UploadFile classUpload="c-data-file c-data-file-big" type="screenshot_t" accept=".png,.jpg,.jpeg"  onOk={data => {
										screenshotT.push(data)
										this.setState({
											screenshotT: screenshotT
										})
									}}></UploadFile>
								)}
								<Paragraph>
									文件数量：4~5；文件格式：png，jpg；文件尺寸：1080*1920px；文件大小：不超过1M
								</Paragraph>
							</Form.Item>
							<Form.Item>
									<Button type="primary" loading={this.loading} onClick={this.handleSubmit} className="login-form-button">
											确认
									</Button>
							</Form.Item>
						</Form>
					</div>
				</div>
			</div>
		)
	}
});
Edit = Form.create()(Edit);
export default Edit;