import React, { Component } from 'react';
import {observer} from "mobx-react";
import {extendObservable} from "mobx";
import { Typography, Select, Row, Col, Empty, Button, Spin, Icon, Breadcrumb } from 'antd';
import Header from '../components/Header';
import Service from '../service';
const { Title } = Typography;
const { Option } = Select;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
export default observer(class Index extends Component {
	constructor(props){
		super(props);
		this.state = {
			packageName:  this.props.match.params.package,
			initLoading: true,
			emptyMsg: '',
			versionList: [],
			versionId: '',
			packageInfo: {}
		}
		this.getVersion()
	}
	getVersion() {
		Service.getVersionByPackage({
			packageName: this.state.packageName
		}).then(res => {
			if (res.code === 200) {
				this.setState({
					versionId: res.data[0],
					versionList: res.data
				}, () => {
					if (res.data && res.data.length) {
						this.getPackInfo()
					}
					else {
						this.setState({
							initLoading: false
						})
					}
				})
			}
			else {
				this.setState({
					initLoading: false,
					emptyMsg: res.msg || ''
				})
			}
		})
	}
	getPackInfo() {
		let { packageName, versionId } = this.state
		Service.getMaterialInfo({
			packageName: packageName,
			version: versionId
		}).then(res => {
			if (res.code === 200) {
				this.setState({
					packageInfo: res.data,
					initLoading: false,
					emptyMsg: res.msg || ''
				})
			}
			else {
				this.setState({
					initLoading: false,
					emptyMsg: res.msg || ''
				})
			}
		})
	}
	render() {
		let { versionList, versionId, packageInfo, emptyMsg, packageName, initLoading } = this.state
		return (
			<div className={"c-data " + (this.props.type ? 'hide-header' : '')}>
				<Header {...this.props}/>
				<div className="c-data-main">
					{this.props.type && (
						<Breadcrumb style={{marginTop: 20, marginLeft: 20}}>
							<Breadcrumb.Item onClick={e => {
								this.props.history.push('/newindex')
							}} className="cursor"><Icon type="home" /> 应用信息</Breadcrumb.Item>
							<Breadcrumb.Item>{packageName}</Breadcrumb.Item>
						</Breadcrumb>
					)}
					<div className="c-data-list clearfix" style={{width: 800}}>
						<Row>
							<Col span={24} className="text-center">
								<Title level={3}>历史版本</Title>
							</Col>
						</Row>	
						{initLoading ? (
							<div className="example">
								<Spin indicator={antIcon}/>
							</div>
						) : 		
							versionId ? (
								<div>
									<Row gutter={[16, 16]}>
										<Col span={6} className="c-data-label">
											包名：
										</Col>
										<Col span={18} className="c-data-label">
											{packageName}
										</Col>
									</Row>
									<Row gutter={[16, 16]}>
										<Col span={6} className="c-data-label">
											版本
										</Col>
										<Col span={8}>
											<Select value={versionId} style={{ width: '100%' }} onChange={e => {
												this.setState({
													versionId: e,
													initLoading: true
												}, () => {
													this.getPackInfo()
												})
											}}>
												{versionList.map((version, key) => {
														return (
																<Option value={version} key={key}>{version}</Option>
														)
												})}
											</Select>
										</Col>
									</Row>
									<Row gutter={[16, 16]}>
										<Col span={6} className="c-data-label">
											小版本：
										</Col>
										<Col span={18} className="c-data-label">
											<p className="c-data-desc">{packageInfo.smallVersion}</p>
										</Col>
									</Row>
									<Row gutter={[16, 16]}>
										<Col span={6} className="c-data-label">
											副标题：
										</Col>
										<Col span={18} className="c-data-label">
											<p className="c-data-desc">{packageInfo.subtitle}</p>
										</Col>
									</Row>
									<Row gutter={[16, 16]}>
										<Col span={6} className="c-data-label">
											更新说明：
										</Col>
										<Col span={18} className="c-data-label">
											<p className="c-data-desc">{packageInfo.updatedescription}</p>
										</Col>
									</Row>
									<Row gutter={[16, 16]}>
										<Col span={6} className="c-data-label">
											一句话推荐（8字）：
										</Col>
										<Col span={18} className="c-data-label">
											<p className="c-data-desc">{packageInfo.recommenteight}</p>
										</Col>
									</Row>
									<Row gutter={[16, 16]}>
										<Col span={6} className="c-data-label">
										一句话推荐（15字）：
										</Col>
										<Col span={18} className="c-data-label">
											<p className="c-data-desc">{packageInfo.recommentfifteen}</p>
										</Col>
									</Row>
									<Row gutter={[16, 16]}>
										<Col span={6} className="c-data-label">
											关键词：
										</Col>
										<Col span={18} className="c-data-label">
											<p className="c-data-desc">{packageInfo.keyword}</p>
										</Col>
									</Row>
									<Row gutter={[16, 16]}>
										<Col span={6} className="c-data-label">
											隐私政策：
										</Col>
										<Col span={18} className="c-data-label">
											<p className="c-data-desc">{packageInfo.privacyPolicy}</p>
										</Col>
									</Row>
									<Row gutter={[16, 16]}>
										<Col span={6} className="c-data-label">
											发布方式：
										</Col>
										<Col span={18} className="c-data-label">
											<p className="c-data-desc">{packageInfo.publishTime?"定时发布:"+packageInfo.publishTime:"审核后立即发布"}</p>
										</Col>
									</Row>
									<Row gutter={[16, 16]}>
										<Col span={6} className="c-data-label">
											应用描述（500字）：
										</Col>
										<Col span={18} className="c-data-label">
											<p className="c-data-desc">{packageInfo.appdescriptionfive}</p>
										</Col>
									</Row>
									<Row gutter={[16, 16]}>
										<Col span={6} className="c-data-label">
											应用描述（1000字）：
										</Col>
										<Col span={18} className="c-data-label">
											<p className="c-data-desc">{packageInfo.appdescriptionten}</p>
										</Col>
									</Row>
									<Row gutter={[16, 16]}>
										<Col span={6} className="c-data-label">
											应用描述（不限字数）：
										</Col>
										<Col span={18} className="c-data-label">
											<p className="c-data-desc">{packageInfo.appdescription}</p>
										</Col>
									</Row>
									<Row gutter={[16, 16]}>
										<Col span={6} className="c-data-label">
											应用图标(圆角)：
										</Col>
										<Col span={18} className="c-data-label">
											<img alt="" width={48} className="app_icon" src={packageInfo.iconR}/>
										</Col>
									</Row>
									<Row gutter={[16, 16]}>
										<Col span={6} className="c-data-label">
											应用图标(直角)：
										</Col>
										<Col span={18} className="c-data-label">
											<img alt="" width={48} className="app_icon" src={packageInfo.iconZ}/>
										</Col>
									</Row>
									<Row gutter={[16, 16]}>
										<Col span={6} className="c-data-label">
											截图（480*800px）：
										</Col>
										<Col span={18} className="c-data-label">
											<div className="c-data-banner">
												{packageInfo.screenshotF ? packageInfo.screenshotF.split(';').map((screen, key) => (
													<div className="c-data-banner-item" key={key} style={{backgroundImage: "url("+screen+")"}}></div>
												)) : null}
											</div>
										</Col>
									</Row>
									<Row gutter={[16, 16]}>
										<Col span={6} className="c-data-label">
											截图（1080*1920px）：
										</Col>
										<Col span={18} className="c-data-label">
											<div className="c-data-banner">
												{packageInfo.screenshotT ? packageInfo.screenshotT.split(';').map((screen, key) => (
													<div className="c-data-banner-item" key={key} style={{backgroundImage: "url("+screen+")"}}></div>
												)) : null}
											</div>
										</Col>
									</Row>
							</div>
							) : (
								<Empty
									style={{marginTop: 60}}
									image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
									imageStyle={{
										height: 60,
									}}
									description={
										<span>
											{emptyMsg || '还没有发现新版本，赶快创建一个吧~'}
										</span>
									}
								>
									<Button type="primary" onClick={e => {
										if (emptyMsg) {
											this.props.history.push(this.props.type ? '/newindex' : '/index')
										}
										else {
											if (this.props.type) {
												this.props.history.push('/newedit/' + packageName)
											}
											else {
												this.props.history.push('/edit/' + packageName)
											}
										}
									}}>
										{emptyMsg ? '返回首页' : '立即创建'}
									</Button>
								</Empty>
							)}
					</div>
				</div>
			</div>
		)
	}
});