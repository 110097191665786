import React, { Component } from 'react';
import {extendObservable, computed, autorun} from "mobx";
import {observer} from "mobx-react";
import { Button, Form, Input, message } from 'antd';
import Service from '../service';
import common from '../common';
import leftBg from '../static/image/logo-bg.png'
import rightLogo from '../static/image/logo.png'
let Login = observer(class Login extends Component {
  constructor(props){
		super(props);
		extendObservable(this, {
			loading: false
		});
	}
	handleSubmit = e => {
		e.preventDefault();
		if (this.loading) {
			return
		}
		this.props.form.validateFields((err, values) => {
		  if (!err) {
				this.loading = true
				Service.userLogin(values).then(res => {
					if (res.code === 200) {
						message.success('登录成功');
						this.props.history.push({
							pathname: '/tools/wuliao'
						});
					}
					else {
						message.error(res.msg || '登录失败');
					}
					this.loading = false
				})
		  }
		});
	};
	
  render() {
		const { getFieldDecorator } = this.props.form;
		return (
			<div className="c-logo c-bg c-scope">
					<div className="c-logo-main">
					<div className="c-logo-main-left">
						<div className="logo-main-left-info">
							<img className="bg" alt="" src={leftBg}/>
							<h2>多应用信息管理</h2>
							<h4>管理不同产品  一键上传多规格应用信息</h4>
						</div>
					</div>
					<div className="c-logo-main-right">
						<img className="logo" alt="" src={rightLogo}/>
						<div className="logo-main-right-info">
							<div className="logo-main-right-title">
								登录账户
							</div>
							<Form onSubmit={this.handleSubmit}>
								<Form.Item
									wrapperCol={{ span: 24}}
								>
									{getFieldDecorator('account', {
										initialValue: '',
										rules: [
											{
												required: true,
												message: '账号不能为空',
											},
										]
									})(<Input type="text" placeholder="账户"/>)}
								</Form.Item>
								<Form.Item
									wrapperCol={{ span: 24 }}
								>
									{getFieldDecorator('password', {
										initialValue: '',
										rules: [
											{
												required: true,
												message: '密码不能为空',
											},
										]
									})(<Input.Password placeholder="密码"/>)}
								</Form.Item>
								<Form.Item>
									<Button type="primary" loading={this.loading} htmlType="submit" className="login-form-button">
										登录
									</Button>
								</Form.Item>
							</Form>
						</div>
					</div>
				</div>
			</div>
		);
	}
});
Login = Form.create()(Login);
export default Login;